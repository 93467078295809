import React, { useState, useEffect, useRef } from "react";
import { UserPresence } from "../../../Widget/src/interfaces/UserPresence";
import moment, { Moment } from "moment";
import UAParser from "ua-parser-js";

interface LiveUserDotProps {
	uid: string;
	clientKey: string;
	user: UserPresence;
	now: Moment;
	x: number;
	y: number;
	showStagnant: boolean;
}

const getPathStep = (queued: boolean, path: string) => {
	const parts = (path ?? "").toLowerCase().split("/");
	if (queued) {
		return 0;
	} else if (parts.includes("my")) {
		return 1;
	} else if (
		parts.includes("tickets") ||
		parts.includes("registration") ||
		parts.includes("details") ||
		parts.length < 5
	) {
		return 2;
	} else if (parts.includes("assigned")) {
		return 3;
	} else if (parts.includes("questions")) {
		return 4;
	} else if (parts.includes("cart")) {
		return 5;
	} else if (parts.includes("checkout")) {
		return 6;
	} else if (parts.includes("results")) {
		return 7;
	}
	return 2;
};

export const usePrevious = (value: any) => {
	const ref = useRef();
	useEffect(() => {
		ref.current = value;
	});
	return ref.current;
};

const LiveUserDot: React.FC<LiveUserDotProps> = ({ uid, clientKey, user, now, x, y, showStagnant }) => {
	const [pulse, setPulse] = useState(false);
	const ax = (getPathStep(user.allow === false, user.path) + x) / 8;

	const oldTs = usePrevious(user.ts);
	const oldUser = usePrevious(user) as UserPresence | undefined;

	const stagnant = now.diff(moment.utc(user.uts || user.ts), "seconds") > 120;

	useEffect(() => {
		if (!!oldTs && !!user.ts && oldTs !== user.ts) {
			if (moment.utc(user.ts).diff(moment.utc(oldTs), "seconds") > 1) {
				console.log(oldTs, user.ts);
				console.count(
					`https://console.firebase.google.com/u/0/project/brushfire-widget-source/database/brushfire-widget-source-default-rtdb/data/~2Fpresence~2F${clientKey}~2F${uid}`
				);
			}
			setPulse(true);
			setTimeout(() => {
				if (user.allow) {
					console.count("in");
					//console.log(user);
				} else {
					console.count("out");
					console.log(user);
				}
				setPulse(false);
			}, 500);
		}
	}, [user.ts]);

	// useEffect(() => {
	// 	console.count("incoming: ?");
	// }, []);

	// if (oldUser?.allow === false && user.allow === true) {
	// 	const parser = new UAParser(user.ua);
	// 	// parser.setUA(user.ua);
	// 	// console.log("BOOM", parser.getResult().browser, parser.getResult().device);
	// 	// console.log("BOOM", oldUser, user);
	// }

	const countThem =
		(!!user.uts || !!user.ts) &&
		(now.diff(moment.utc(user.uts || user.ts), "seconds") < 120 || showStagnant) &&
		user.connected &&
		!!user.path;

	if (!countThem) {
		return null;
	}
	return (
		<div
			className={`user-dot ${user.connected ? "connected" : ""} ${stagnant ? "stale" : ""} ${
				user.uts === user.ts ? "new" : ""
			} ${pulse ? "pulse" : ""}`}
			title={`${clientKey} ${uid}`}
			style={{ left: `${ax * 100}%`, top: `${y * 100}%` }}
			onClick={() => {
				window.open(
					`https://console.firebase.google.com/u/0/project/brushfire-widget-source/database/brushfire-widget-source-default-rtdb/data/~2Fattendees~2F${clientKey}~2F${uid}`,
					"_blank"
				);
			}}
		></div>
	);
};

export default LiveUserDot;
