import { Moment } from "moment";
import moment from "moment-timezone";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Button, Dropdown, Form } from "react-bootstrap";
import { useHistoricalData } from "./HistoricalProvider";

interface ViewDropdownProps {}

const ViewDropdown: React.FC<ViewDropdownProps> = () => {
	const [intermediateDate, setIntermediateDate] = useState<Moment>(moment().startOf("minute"));
	const { selectedDate, setSelectedDate } = useHistoricalData();

	return (
		<Dropdown className="dataview-dropdown">
			<Dropdown.Toggle variant="light" title="Select View" className="trigger">
				{selectedDate == null ? (
					<>
						<i className="fas fa-bolt-lightning" />
						<b>LIVE</b>
					</>
				) : (
					<>
						<i className="fas fa-clock" />
						<b>{`${selectedDate.locale("en").format("MMMM Do, YYYY h:mmA z")}`}</b>
					</>
				)}
			</Dropdown.Toggle>
			<Dropdown.Menu align="end" className="dropdown-menu-sm menu">
				<Dropdown.Item as="button" className="item" onClick={() => setSelectedDate(null)}>
					<i className="fas fa-bolt-lightning" />
					<b style={{ padding: "6px 12px" }}>LIVE</b>
					<i className="fas fa-caret-right" />
				</Dropdown.Item>
				<Dropdown.Item
					as="button"
					className="item"
					onClick={() => {
						const newDate = intermediateDate;
						if (newDate.isAfter(moment())) {
							setSelectedDate(null);
						} else {
							setSelectedDate(intermediateDate);
						}
					}}
				>
					<i className="fas fa-clock" />
					<Form.Control
						style={{ fontWeight: "bolder" }}
						onClick={(e) => e.stopPropagation()}
						onChange={(e) => {
							moment.tz.setDefault("America/Chicago");
							setIntermediateDate(moment(e.target.value));
						}}
						type="datetime-local"
						defaultValue={moment()
							.subtract(1, "hour")
							.startOf("minute")
							.format("YYYY-MM-DD HH:mm:ss")}
						step={60}
					/>
					<i className="fas fa-caret-right" />
				</Dropdown.Item>
			</Dropdown.Menu>
		</Dropdown>
	);
};

export default ViewDropdown;
