import { EventSingleOutput } from "@brushfire/types";
import React, { useState, useEffect } from "react";
import { doc, onSnapshot, getFirestore } from "firebase/firestore";
import fb from "../firebase";
import { GlobalSettings } from "../../../Widget/src/interfaces/UserPresence";
import EventHeader from "./EventHeader";
import Skeleton from "@material-ui/lab/Skeleton";

interface MaintenanceViewProps {
	settings: GlobalSettings;
	eventNumber?: string;
	isStaging: boolean;
}

const MaintenanceView: React.FC<MaintenanceViewProps> = ({ settings, eventNumber, isStaging }) => {
	const [event, setEvent] = useState<EventSingleOutput>();
	const eventsCollection = `events${isStaging ? "-staging" : ""}`;

	const firestore = getFirestore(fb);

	useEffect(() => {
		if (eventNumber) {
			return onSnapshot(doc(firestore, eventsCollection, eventNumber), (doc) => {
				setEvent(doc.data() as EventSingleOutput);
			});
		}
	}, [eventNumber]);
	return (
		<>
			{event ? (
				<div className="ta-left">
					<EventHeader
						event={event}
						currentTab={2}
						isLoading={!event}
						isWidget
						userCanManage={false}
						path={""}
					/>
				</div>
			) : (
				<>
					{!!eventNumber && (
						<div className="well lg ta-center d-block">
							<div className={`well-content`}>
								<>
									<Skeleton variant="rect" width="70%" height="36px" className="m-bottom" />
									<Skeleton variant="rect" width="35%" />
								</>
							</div>
						</div>
					)}
				</>
			)}

			<div className="well lg ta-center d-block">
				<div className={`well-content`}>
					<h1>{settings.maintenance.heading}</h1>
					<p>{settings.maintenance.body}</p>
				</div>
			</div>
		</>
	);
};

export default MaintenanceView;
