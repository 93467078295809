import "./App.css";
import "./Styles/.CSS/main.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Route, BrowserRouter, Routes as Switch } from "react-router-dom";
import Dashboard from "./Dashboard/Dashboard";
import EntryQueue from "./EntryQueue/EntryQueue";
import { Routes } from "./Constants";
import QueueHome from "./EntryQueue/QueueHome";
import { HistoricalProvider } from "./Dashboard/HistoricalProvider";

function App() {
	const isStaging = window.location.hostname === "brushfirestaging.com";
	return (
		<div className="App">
			<BrowserRouter>
				<HistoricalProvider isStaging={isStaging}>
					<Switch>
						<Route
							index
							path={Routes.dashboard}
							element={<Dashboard path={Routes.dashboard} isStaging={isStaging} />}
						/>
						<Route path="/" element={<QueueHome />} />
						<Route path="/*" element={<EntryQueue isStaging={isStaging} />} />
					</Switch>
				</HistoricalProvider>
			</BrowserRouter>
		</div>
	);
}

export default App;
