import fb from "../firebase";
import { Moment } from "moment";
import moment from "moment-timezone";
import React, { Dispatch, SetStateAction, useContext, useEffect, useRef, useState } from "react";
import { doc, getDoc, getFirestore } from "firebase/firestore";
import { LoadLog } from "../../../Widget/src/interfaces/UserPresence";
import { useLocation } from "react-router-dom";
import { Routes } from "../Constants";

interface HistoricalContext {
	isLive: boolean;
	selectedDate: Moment | null;
	setSelectedDate: Dispatch<SetStateAction<Moment | null>>;
	loadingData: boolean;
	historicalData: LoadLog[];
	onGoForward: () => void;
	onGoBackward: () => void;
	showNextHourButton: boolean;
}

interface LoadDocument {
	[timestamp: string]: LoadLog;
}

export const HistoricalContext = React.createContext<HistoricalContext>({
	isLive: true,
	selectedDate: null,
	setSelectedDate: () => {},
	loadingData: false,
	historicalData: [],
	onGoForward: () => {},
	onGoBackward: () => {},
	showNextHourButton: true,
});

const sortLoadLogsByTime = (a: LoadLog, b: LoadLog) => moment(a.ts).diff(moment(b.ts));

export const HistoricalProvider = ({ children, isStaging }: { children: React.ReactNode; isStaging: boolean }) => {
	const [selectedDate, setSelectedDate] = useState<Moment | null>(null);
	const [allHistoricalData, setAllHistoricalData] = useState<LoadLog[]>([]);
	const [loadingData, setLoadingData] = useState<boolean>(false);
	const [showNextHourButton, setShowNextHourButton] = useState<boolean>(true);
	const [historicalData, setHistoricalData] = useState<LoadLog[]>([]);
	const loadCollection = `load${isStaging ? "-staging" : ""}`;
	const dateChangedFromArrows = useRef<boolean>(false);

	const fetchHistoricalData = async () => {
		try {
			setLoadingData(true);
			const firestore = getFirestore(fb);

			const loadData: LoadLog[] = [];

			// We need to fetch the doc associated with the timestamp, one before, and one after

			const date = moment(selectedDate).utc();

			const selectedHour = date.format("YYYYMMDDHH");
			const selectedHourDoc = await getDoc(doc(firestore, loadCollection, selectedHour));

			const previousHour = date.subtract(1, "hour").format("YYYYMMDDHH");
			const previousHourDoc = await getDoc(doc(firestore, loadCollection, previousHour));

			const nextHour = date.add(2, "hour").format("YYYYMMDDHH"); // We have to add 2 because we subtracted one before
			const nextHourDoc = await getDoc(doc(firestore, loadCollection, nextHour));

			try {
				for (const doc of [previousHourDoc, selectedHourDoc, nextHourDoc]) {
					if (doc.exists()) {
						const data = doc.data() as LoadDocument;
						loadData.push(
							...Object.keys(data)
								.filter((key) => key !== "ts")
								.map((key) => data[key])
						);
					} else {
						console.error(`Document ${doc.id} does not exist!`); // TODO: Do something
					}
				}
			} catch (err) {
				console.error(err);
				setHistoricalData([]);
				setLoadingData(false);
			}

			loadData.sort(sortLoadLogsByTime);
			setAllHistoricalData(loadData);

			// Get first loadlog after selectedDate
			const firstAfterIdx = loadData.findIndex((loadLog) => moment.utc(loadLog.ts).isAfter(selectedDate));
			const start = Math.max(0, firstAfterIdx - 50);
			const end = Math.min(loadData.length, firstAfterIdx + 50);

			setHistoricalData(loadData.slice(start, end));
		} catch (error) {
			console.error(error);
			setHistoricalData([]);
		} finally {
			setLoadingData(false);
		}
	};

	useEffect(() => {
		// we only want to fetch new data if they manually go in and set a new date, not when we update by going forward/backwards
		if (selectedDate && !dateChangedFromArrows.current) {
			fetchHistoricalData();
		} else if (dateChangedFromArrows.current) {
			// reset
			dateChangedFromArrows.current = false;
		} else {
			setHistoricalData([]);
		}
	}, [selectedDate]);

	const onGoForward = async () => {
		// New center date is the last viewable date
		dateChangedFromArrows.current = true;
		const nextDate = moment(historicalData.at(-1)?.ts);
		setSelectedDate(nextDate);

		// we need to check and see if we have 50 more data points after the last data point in view
		const idxOfLastPointInView = allHistoricalData.findIndex((log) => log.ts === historicalData.at(-1)?.ts);
		const needMoreFutureData = allHistoricalData.length - idxOfLastPointInView < 51; // Number of timestamps after last in view is greater than 50

		if (needMoreFutureData) {
			const firestore = getFirestore(fb);
			const nextHour = moment
				.utc(allHistoricalData.at(-1)?.ts)
				.add(1, "hour")
				.format("YYYYMMDDHH");
			try {
				const nextHourDoc = await getDoc(doc(firestore, loadCollection, nextHour));

				if (nextHourDoc.exists()) {
					// add to all historical data
					const data = nextHourDoc.data() as LoadDocument;
					const newData = [
						...allHistoricalData,
						...Object.keys(data)
							.filter((key) => key !== "ts")
							.map((key) => data[key]),
					].sort(sortLoadLogsByTime);

					// Update viewable historical data
					const newStart = newData.findIndex((log) => log.ts === historicalData.at(50)?.ts);
					setHistoricalData(newData.slice(newStart, newStart + 100));
					setAllHistoricalData(newData);
					setShowNextHourButton(true);
				} else {
					// Update viewable historical data
					const newStart = allHistoricalData.findIndex((log) => log.ts === historicalData.at(50)?.ts);
					const nextSlice = allHistoricalData.slice(newStart, newStart + 100);
					const hasEnoughData = nextSlice.length === 100;
					if (hasEnoughData) {
						setHistoricalData(nextSlice);
					} else {
						setShowNextHourButton(false);
						const lastSlice = allHistoricalData.slice(-100);
						setHistoricalData(lastSlice);
					}
				}
			} catch (error) {
				console.error(error);
			}
		} else {
			const newStart = allHistoricalData.findIndex((log) => log.ts === historicalData.at(50)?.ts);
			setHistoricalData(allHistoricalData.slice(newStart, newStart + 100));
		}
	};
	const onGoBackward = async () => {
		// New center date is the first viewable date
		dateChangedFromArrows.current = true;
		setSelectedDate(moment(historicalData.at(0)?.ts));

		// Check and see if we have 50 data points before the first data point;
		const idxOfFirstPointInView = allHistoricalData.findIndex((log) => log.ts === historicalData.at(0)?.ts);
		const needMorePastData = idxOfFirstPointInView < 49;
		let newHistoricalData: LoadLog[] = [];

		if (needMorePastData) {
			const firestore = getFirestore(fb);
			const previousHour = moment
				.utc(allHistoricalData.at(0)?.ts)
				.subtract(1, "hour")
				.format("YYYYMMDDHH");
			try {
				const previousHourDoc = await getDoc(doc(firestore, loadCollection, previousHour));

				if (previousHourDoc.exists()) {
					const data = previousHourDoc.data() as LoadDocument;
					const newData = [
						...Object.keys(data)
							.filter((key) => moment(key).isValid())
							.map((key) => data[key]),
						...allHistoricalData,
					].sort(sortLoadLogsByTime);

					// Update viewable historical data
					const newStart = newData.findIndex((log) => log.ts === historicalData.at(0)?.ts) - 50;
					newHistoricalData = newData.slice(newStart, newStart + 100);
					setAllHistoricalData(newData);
				} else {
					// I guess this would mean they went all the way back to the first data point - do nothing
					console.error(`${previousHour} doc does not exist`);
				}
			} catch (error) {
				console.error(error);
			}
		} else {
			const newStart = idxOfFirstPointInView - 50;
			newHistoricalData = allHistoricalData.slice(newStart, newStart + 100);
		}

		setHistoricalData(newHistoricalData);
		setShowNextHourButton(true);
	};

	const contextValue: HistoricalContext = {
		isLive: selectedDate === null,
		selectedDate,
		setSelectedDate,
		historicalData,
		loadingData,
		onGoForward,
		onGoBackward,
		showNextHourButton,
	};

	return <HistoricalContext.Provider value={contextValue}>{children}</HistoricalContext.Provider>;
};

export const useHistoricalData = () => {
	const context = useContext(HistoricalContext);
	return context;
};
